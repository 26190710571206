import { StyleSheet } from '@react-pdf/renderer';

export const PdfGeneratorStyles = StyleSheet.create({
    problemContainer: {  
      justifyContent: 'right', 
      width: 'auto',
    },
    longDivisionProblemContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'right', 
      width: 'auto',
    },
    height60: {
      height: '60px !important'
    },
    height72: {
      height: '72px !important'
    },
    height100: {
      height: '100px !important'
    },
    height120: {
      height: '120px !important'
    },
    height150: {
      height: '150px !important'
    },
    digit: {
      fontFamily: 'Helvetica',
      fontSize: 26,
      marginLeft: 'auto',
      marginRight: '0'
    },
    font26: {
      fontSize: 26,
    },
    font20: {
      fontSize: 20,
    },
    font15: {
      fontSize: 15,
    },
    bottomDigit:{
      textDecoration: 'underline',
    },
    longDivisionFirstNumber: {
      borderRight: 2,
      borderBottomRightRadius: 10,
      paddingRight: 4,
      paddingTop: 4,
    },
    longDivisionSecondNumber: {
      borderTop: 2, 
      paddingLeft: 4,
      paddingTop: 4,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '90%',
      margin: 'auto',
    },
    problemsContainer: {
      marginBottom: '20px',
    },   
  });

  