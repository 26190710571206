import { DoubleDigitNumbers, OperationOptions, Operations, RegroupingOptions, SelectOption, SingleDigitNumbers, TripleDigitNumbers } from "../Constants.ts";
import { DefaultSettings, NumberRange, SettingsProps } from "../Models.ts";

export function ProcessUrl(queryString: string) {    
    const urlParams = new URLSearchParams(queryString);
    let initialSettings = JSON.parse(JSON.stringify(DefaultSettings));
    if (urlParams.size > 0) {
        initialSettings = deconstructURL(urlParams);
    }
    return initialSettings;
}

function deconstructURL(urlParams: URLSearchParams) {
    const add = urlParams.get('Addition');
    const sub = urlParams.get('Subtraction');
    const mul = urlParams.get('Multiplication');
    const div = urlParams.get('Division');
    const lDiv = urlParams.get('Long Division');
    const v1d = urlParams.get('v1d');
    const v1r = urlParams.get('v1r');
    const v2d = urlParams.get('v2d');
    const v2r = urlParams.get('v2r');
    const ppp = urlParams.get('ppp');
    const np = urlParams.get('np');
    const ap = urlParams.get('ap');
    const hp = urlParams.get('hp');
    const r = urlParams.get('r');
    const rg = urlParams.get('rg');

    let operators: SelectOption[] = [];
    if (add === '1') {
        operators.push(OperationOptions.filter(op => op.value === Operations.Addition)[0]);
    }
    if (sub === '1') {
        operators.push(OperationOptions.filter(op => op.value === Operations.Subtraction)[0]);
    }
    if (mul === '1') {
        operators.push(OperationOptions.filter(op => op.value === Operations.Multiplication)[0]);
    }
    if (div === '1') {
        operators.push(OperationOptions.filter(op => op.value === Operations.Division)[0]);
    }
    if (lDiv === '1') {
        operators.push(OperationOptions.filter(op => op.value === Operations.LongDivision)[0]);
    }
    const nr1Digits = v1d ? parseInt(v1d) : 1;
    let range1 = setNumberRange(nr1Digits);
    let numberRanges: NumberRange[] = [];
    let numberRange1: NumberRange = {
        Id: 1,
        NumberOfDigits: nr1Digits,
        NumberRange: range1,
        NumberRangeSelection: 
            v1r ? 
            v1r!.split(',').map((number) => parseInt(number)) :
            DefaultSettings.NumberRanges[0].NumberRangeSelection
    };
    numberRanges.push(numberRange1);
    

    const nr2Digits = v2d ? parseInt(v2d) : 1;
    let range2 = setNumberRange(nr2Digits);
    let numberRange2: NumberRange = {
        Id: 2,
        NumberOfDigits: nr2Digits,
        NumberRange: range2,
        NumberRangeSelection: 
            v2r ? 
            v2r!.split(',').map((number) => parseInt(number)) :
            DefaultSettings.NumberRanges[1].NumberRangeSelection
    };
    numberRanges.push(numberRange2);


    let urlSettings: SettingsProps = {
        Operators: operators,
        NumberOfProblemsPerPage: ppp && !isNaN(parseInt(ppp)) ? parseInt(ppp) : 30,
        NumberOfPages: np && !isNaN(parseInt(np!)) ? parseInt(np!) : 1,
        NumberRanges: numberRanges,
        AnswerPages: ap === '1' ? true : false,
        HalfPage: hp === '1' ? true : false,
        Remainders: r === '1' ? true : false,
        Regrouping: rg ? RegroupingOptions[rg.replace(/\s+/g, '')] : DefaultSettings.Regrouping
    };
    
    return urlSettings;
}

function setNumberRange(NumberOfDigits) {
    let range: number[] = [];
    switch(NumberOfDigits) {            
        case 2: {
            range = DoubleDigitNumbers.Range;
            break;
        }
        case 3: {
            range = TripleDigitNumbers.Range;
            break;
        }
        default: {
            range = SingleDigitNumbers.Range;
            break;
        }            
    };
    return range;
}