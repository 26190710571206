import { Button } from "primereact/button";
import { DigitNumbers, DoubleDigitNumbers, SingleDigitNumbers, TripleDigitNumbers } from "../Constants.ts";
import React, { useContext } from "react";
import { SettingsContext } from "./SettingsContext.tsx";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';

export function RangeButtons({rangeGroup, range}) {
    const {settings, setSettings} = useContext(SettingsContext);

    function handleNumberRangeButtonClick(e, rangeGroup, ) {
        let updatedSettings = JSON.parse(JSON.stringify(settings));
        let range = updatedSettings.NumberRanges.filter(x => x.Id === rangeGroup)[0];
        switch(e.target.id) {            
            case 'lowRange': {
                const digitNumbers = checkNumberRange(range.NumberRange);
                range.NumberRangeSelection = digitNumbers.LowRange;
                break;            
            }
            case 'highRange': {
                const digitNumbers = checkNumberRange(range.NumberRange);
                range.NumberRangeSelection = digitNumbers.HighRange;
                break;
            }
            case 'allRange': {
                range.NumberRangeSelection = range.NumberRange;
                break;
            }
            default: {
                range.NumberRangeSelection = [];
                break;
            }
        }
        setSettings(updatedSettings);
    }

    let labels: string[] = [];
    switch(range[range.length - 1]) {
        
        case DoubleDigitNumbers.Range[DoubleDigitNumbers.Range.length - 1]: {
            labels = ['10-40', '50-90'];
            break;
        }
        case TripleDigitNumbers.Range[TripleDigitNumbers.Range.length - 1]: {
            labels = ['100-400', '500-900'];
            break;
        }
        default : {
            labels = ['0-4', '5-9'];
            break;
        }
    }
    const rangeButtons = (
        <div>   
            <Button  id='lowRange' onClick={(e) => handleNumberRangeButtonClick(e, rangeGroup)}>{labels[0]}</Button >
            <Button  id='highRange' onClick={(e) => handleNumberRangeButtonClick(e, rangeGroup)}>{labels[1]}</Button >
            <Button  id='allRange' onClick={(e) => handleNumberRangeButtonClick(e, rangeGroup)}>All</Button >
            <Button  id='clearRange' onClick={(e) => handleNumberRangeButtonClick(e, rangeGroup)}>Clear</Button >
        </div>
    );
    return rangeButtons;
}  

function checkNumberRange(range) : DigitNumbers {
    switch(range[0].toString().length) {            
        case 2: {
            return DoubleDigitNumbers;
        }
        case 3: {
            return TripleDigitNumbers;
        }
        default: {
            return SingleDigitNumbers;
        }
    }
}

  
   