export interface ISettings {
    NumberOfMissingNumbers: number;
    NumberOfPages: number;
}

export const DefaultSettings: ISettings = {
    NumberOfMissingNumbers: 0,
    NumberOfPages: 1
}

export const MissingNumberOptions: number[] = [0, 10, 20, 30, 40, 50];
