import { getRandomInt } from "../Shared/Helpers.tsx";
import { OperationOptions, Operations, ProblemData, RegroupingOptions } from "./Constants.ts";
import { SettingsProps } from "./Models.tsx";

export function GenerateDataV2(settings: SettingsProps) {
  if (settings?.Operators === null || settings?.Operators?.length === 0) {
    return null;
  }

  if (settings?.NumberRanges[0].NumberRangeSelection.length === 0 || settings?.NumberRanges[1].NumberRangeSelection.length === 0) {
    return null;
  }

  let problems: ProblemData[] = [];
  let pages: ProblemData[][] = [];

  const numberOfProblemsPerPage = settings.NumberOfPages * settings.NumberOfProblemsPerPage;
  const operators = settings?.Operators?.map(op => op.value);
  const numberOfProblemsForEachOperator = Math.ceil(numberOfProblemsPerPage / operators.length);

  let loops = 0;
  while (problems.length < numberOfProblemsPerPage && loops < 10) {
    loops++;
    for (let i = 0; i < operators.length; i++) {
      const newProblems = generateRequiredProblems(settings, operators[i], numberOfProblemsForEachOperator);
      if (newProblems.length === 0) {
        continue;
      } else {
        problems = problems.concat(newProblems);
      }    
    }
  }
  shuffleArray(problems);
  problems = problems.slice(0, numberOfProblemsPerPage);

  // validate problems are correct and dispersed properly. Want to avoid duplicates and inverse problems next to each other. As well as problems in sequence.
  // currently only fixing duplicates next to each other
  // todo - fix inverse problems next to each other
  problems = checkAndFixDuplicatesNextToEachOther(problems);

  for (let i = 0; i < settings.NumberOfPages; i++) {
    let problemsPerPage = problems.slice(0, settings.NumberOfProblemsPerPage);
    pages.push(problemsPerPage);
  }
  return pages;
}

function generateRequiredProblems(settings: SettingsProps, operator: Operations, numberOfProblemsNeeded: number) {
  let problems: ProblemData[] = [];
    // get all possible combinations of numbers
    let firstDigits = settings.NumberRanges[0].NumberRangeSelection;
    let secondDigits = settings.NumberRanges[1].NumberRangeSelection;

    if (settings.NumberRanges[0].NumberOfDigits > 1) {
      firstDigits = fillUpNumberRange(settings.NumberRanges[0].NumberOfDigits, firstDigits);
    }

    if (settings.NumberRanges[1].NumberOfDigits > 1) {
      secondDigits = fillUpNumberRange(settings.NumberRanges[1].NumberOfDigits, secondDigits);
    }

    // create duplicates of the numbers to fill the total number of problems
    while (problems.length < numberOfProblemsNeeded) {
      let newProblems = generateProblems(firstDigits, secondDigits, numberOfProblemsNeeded, operator, settings.Regrouping, settings.Remainders);
      if (newProblems.length === 0) {
        console.log('no problems generated');
        return problems;        
      }
      problems = problems.concat(newProblems);
    }

    return problems;
}

function fillUpNumberRange(numberOfDigits: number, numberRange: number[]) {
  let newNumberRange: number[] = [];
  for (let i = 0; i < numberRange.length; i++) {
    let currentNumber = numberRange[i];
    for (let j = 0; j < 10; j++) {
      if (numberOfDigits === 2) {
        newNumberRange.push(currentNumber + j);
      }
      if (numberOfDigits === 3) {
        for (let k = 0; k < 10; k++) {
          newNumberRange.push(currentNumber + (j * 10) + k);
        }
      }
    }
  }
  return newNumberRange;
}

function checkAndFixDuplicatesNextToEachOther(problems: ProblemData[]) {
  let swaps = 1;
    let loops = 0;
    while (swaps !== 0 && loops < 10) {
      swaps = 0;
      loops++;
      if (loops === 10) {
        console.log('loops exceeded');
      }
      for (let i = 0; i < problems.length; i++) {
        if (i > 0) {
          const previousProblem = problems[i - 1];
          const currentProblem = problems[i];
          if (previousProblem.numbers[0] === currentProblem.numbers[0] && previousProblem.numbers[1] === currentProblem.numbers[1]) {
            // swap the current problem with a random problem
            let randomIndex = getRandomInt(i, problems.length - 1);
            let randomProblem = problems[randomIndex];
            while (randomProblem.numbers === currentProblem.numbers) {
              randomIndex = getRandomInt(0, problems.length - 1);
              randomProblem = problems[randomIndex];
            }
            problems[randomIndex] = currentProblem;
            problems[i] = randomProblem;
            console.log('swapped ' + currentProblem.key + ' with ' + randomProblem.key);
            swaps++;
          }
        }
      }
    }
    console.log('loops: ' + loops);

    return problems;
}

function generateProblems(firstDigit: number[], secondDigit: number[], numberOfRequiredProblems: number,
   operator: Operations, regrouping: RegroupingOptions, includeRemainder: boolean) {
  let problems: ProblemData[] = [];
  let loops = 0;
  while (problems.length < numberOfRequiredProblems && loops < numberOfRequiredProblems) {
    loops++;
    const firstNumberIndex = Math.floor(Math.random() * firstDigit.length);
    let firstNumber = firstDigit[firstNumberIndex];
    const secondNumberIndex = Math.floor(Math.random() * secondDigit.length);
    let secondNumber = secondDigit[secondNumberIndex];
    let answer = 0;
    let remainder = 0;
    let operationSymbol = '';
    switch (operator) {
      case Operations.Addition: {
        if (regrouping === RegroupingOptions.NoRegrouping) {
          if (secondNumber < firstNumber) {
            const temp = firstNumber;
            firstNumber = secondNumber;
            secondNumber = temp;
          }
        }
        answer = firstNumber + secondNumber;
        operationSymbol = OperationOptions.find(op => op.value === Operations.Addition)?.sign ?? '';
        break;
      }
      case Operations.Subtraction: {
        if (secondNumber < firstNumber) {
          const temp = firstNumber;
          firstNumber = secondNumber;
          secondNumber = temp;
        }
        answer = secondNumber - firstNumber;
        operationSymbol = OperationOptions.find(op => op.value === Operations.Subtraction)?.sign ?? '';;
        break;
      }
      case Operations.Multiplication: {
        answer = firstNumber * secondNumber;
        operationSymbol = OperationOptions.find(op => op.value === Operations.Multiplication)?.sign ?? '';;
        break;
      }
      case Operations.Division: {
        if (firstNumber === 0) {
          const temp = firstNumber;
          firstNumber = secondNumber;
          secondNumber = temp;
        }
        answer = firstNumber * secondNumber;
        const temp = secondNumber;
        secondNumber = answer;
        answer = temp;
        operationSymbol = OperationOptions.find(op => op.value === Operations.Division)?.sign ?? '';
        break;
      }
      case Operations.LongDivision: {
        if (firstNumber === 0) {
          const temp = firstNumber;
          firstNumber = secondNumber;
          secondNumber = temp;
        }

        answer = firstNumber * secondNumber;
        if (includeRemainder) {
          remainder = Math.floor(Math.random() * (firstNumber - 1));
          answer = answer + remainder;
        }
        const temp = secondNumber;
        secondNumber = answer;
        answer = temp;
        operationSymbol = OperationOptions.find(op => op.value === Operations.LongDivision)?.sign ?? '';;
      }
    }
    let numbers = [secondNumber, firstNumber, answer];
    if (includeRemainder) {
      numbers.push(remainder);
    }
    if (regrouping !== RegroupingOptions.Both) {
      if (!RegroupingCheck(firstNumber, secondNumber, operator, regrouping)) {
        continue;
      }
    }
    problems.push({
      key: problems.length + 1,
      numbers: numbers,
      operator: operationSymbol,
    });
    console.log('problem added: ' + problems.length);
  }   

    return problems;
}

function RegroupingCheck(firstDigit: number, secondDigit: number, operator: Operations, regrouping: RegroupingOptions) {
  const topNumberLastDigit =secondDigit % 10;
  const bottomNumberLastDigit = firstDigit % 10;
  let result = false;
  if (operator === Operations.Addition) {
    const sum = topNumberLastDigit + bottomNumberLastDigit;
    if (regrouping === RegroupingOptions.NoRegrouping) {
      result = sum < 10;
    } else {
      result = sum > 10;
    }
  }

  if (operator === Operations.Subtraction) {
    const difference = topNumberLastDigit - bottomNumberLastDigit;
    if (regrouping === RegroupingOptions.NoRegrouping) {
      result = difference > 0;
    } else {
      result = difference < 0;
    }
  }
  return result;
}

function shuffleArray(array) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {

    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
}