import { RadioButton } from "primereact/radiobutton";
import React, { useContext } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import { SettingsContext } from "./SettingsContext.tsx";
import { FullPageLongDivisionNumberOfProblemsOptions, FullPageNumberOfProblemsOptions, HalfPageLongDivisionNumberOfProblemsOptions, HalfPageNumberOfProblemsOptions } from "../Models.ts";
import { Operations } from "../Constants.ts";

export function NumberOfProblems() {
    const {settings, setSettings} = useContext(SettingsContext);

    function handleNumberOfProblemsSelect(e) {
        let updatedSettings = {...settings};
        updatedSettings.NumberOfProblemsPerPage = Number(e.target.value);
        setSettings(updatedSettings);
    } 

    let options = settings.HalfPage ? HalfPageNumberOfProblemsOptions : FullPageNumberOfProblemsOptions;
    const isLongDivision = settings.Operators.findIndex(x => x.value === Operations.LongDivision) >= 0;
    if (isLongDivision) {
        if (settings.HalfPage) {
            options = HalfPageLongDivisionNumberOfProblemsOptions;
        } else {
            options = FullPageLongDivisionNumberOfProblemsOptions;
        }      
    }
    let radioButtonList: React.JSX.Element[] = [];
    let i = 1;
    options.forEach(option => {

        radioButtonList.push(
            <div key={i}>
                <RadioButton  value={option} checked={settings.NumberOfProblemsPerPage === option} onChange={handleNumberOfProblemsSelect}/>
                <span>{option}</span>
            </div>
        )
        i++;
    });
    
    const radioButtons = (
        <div className='radioButtons'>
            {radioButtonList}
        </div>
    );
    return radioButtons;    
}