import React from 'react';
import { View } from '@react-pdf/renderer';
import { PdfGeneratorStyles } from './Stylesheet.jsx';
import { GenerateVerticalProblem } from '../Problems-UI/Problems.tsx';

export function GeneratePage(props) {
    const styles = PdfGeneratorStyles;
    let problems: JSX.Element[] = [];
    let rows: JSX.Element[] = []; 
    const length = props?.data?.length; 
    for(let i = 0; i < length; i++) {  
      problems.push(GenerateVerticalProblem(props.data[i], length, props.halfPage,
         props.answerPage, props.longDivision, props.remainders));  
      let numberPerRow = length > 30 ? 10 : 5;
      if (props.halfPage) {
        switch(length){
          case 21: {
            numberPerRow = 7
            break;
          }
          case 25: {
            numberPerRow = 7
            break;
          }
          case 30: {
            numberPerRow = 10
            break;
          }
        }
        //numberPerRow = length > 15 ? 7 : 5;
      } 
      const lastRow = (i + 1) === length;
      const createNewRow = (i + 1) % numberPerRow === 0 && i !== 0;
      if (createNewRow || lastRow) {
        const row: JSX.Element = (
          <View key={i + 1} style={styles.row}>
            {problems}
          </View>);
        rows.push(row)
        problems = [];
      }    
    }
const page = (
    <View style={styles.problemsContainer}>
      {rows}
    </View>)
    return page;
  }
