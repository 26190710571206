import React from 'react';
import { Page } from '@react-pdf/renderer';
import { GenerateDataV2 } from '../ProblemGenerator.tsx';
import { GeneratePage } from './Page.tsx';
import { Operations } from '../Constants.ts';
import { BlankHeader, DocumentFooter, DocumentHeader } from '../../Shared/Pdf/PdfComponents.tsx';
import { getRandomInt } from '../../Shared/Helpers.tsx';

export function Pages({settings}) {  

  const longDivision = settings?.Operators?.findIndex(x => x.value === Operations.LongDivision) >= 0;
    const generatedPageData = GenerateDataV2(settings);
    let generatedPageData2;
    if (settings.HalfPage) {
      generatedPageData2 = GenerateDataV2(settings);
    }

    if (!generatedPageData) {
      return null;
    }
    let pagesArray: JSX.Element[] = [];
    
    // Originally j was equal to 1 but clicking on 2 digits then back to 1 digit would mess everything up. This seems to fix it.
    let j = getRandomInt(0, 1000);
    
    for (let i = 0; i < generatedPageData.length!; i++) {
      let pageDisplay: React.JSX.Element;
      if (settings.HalfPage) {
        pageDisplay = (          
          <Page key={j}>
            <DocumentHeader/>
            <GeneratePage data={generatedPageData[i]} halfPage={settings.HalfPage} longDivision={longDivision} remainders={settings.Remainders}/> 
            <DocumentHeader/> 
            <GeneratePage data={generatedPageData2[i]} halfPage={settings.HalfPage} longDivision={longDivision} remainders={settings.Remainders}/>        
            <DocumentFooter/>
          </Page>)
          pagesArray.push(pageDisplay);
          j++;

          if (settings.AnswerPages) {
            pageDisplay = (
              <Page key={j}>
                <BlankHeader/>
                <GeneratePage data={generatedPageData[i]} answerPage={settings.AnswerPages} 
                  halfPage={settings.HalfPage} longDivision={longDivision} remainders={settings.Remainders}/> 
                <BlankHeader/>  
                <GeneratePage data={generatedPageData2[i]} answerPage={settings.AnswerPages} 
                  halfPage={settings.HalfPage} longDivision={longDivision} remainders={settings.Remainders}/>      
                <DocumentFooter/>
              </Page>
            )
            pagesArray.push(pageDisplay);
            j++;
          }

      } else {
        pageDisplay = (
          <Page key={j}>
            <DocumentHeader/>            
            <GeneratePage data={generatedPageData[i]} longDivision={longDivision} remainders={settings.Remainders}/>      
            <DocumentFooter/>
          </Page>)
          pagesArray.push(pageDisplay);
          j++;

          if(settings.AnswerPages) {          
            pageDisplay = (
              <Page key={j}>
                <BlankHeader/>
                <GeneratePage data={generatedPageData[i]} answerPage={settings.AnswerPages}
                  longDivision={longDivision} remainders={settings.Remainders}/>        
                <DocumentFooter/>
              </Page>
            )
            pagesArray.push(pageDisplay);
            j++;
          }  
      }         
    };
    return pagesArray;    
  }


