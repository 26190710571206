export interface SelectOption {
  readonly value: Operations;
  readonly sign: string;
}

export interface DigitNumbers {
Range: number[];
LowRange: number[];
HighRange: number[];
}

export interface ProblemData
{
key: number,
numbers: number[],
operator: string,
}

export const enum Operations {
Addition = "Addition",
Subtraction = "Subtraction",
Multiplication = "Multiplication",
Division = "Division",
LongDivision = "Long Division",
}

export const OperationOptions: SelectOption[] = [
{ sign: '+', value: Operations.Addition},
{ sign: '-', value: Operations.Subtraction},
{ sign: 'x', value: Operations.Multiplication},
{ sign: '÷', value: Operations.Division},
{ sign: '÷', value: Operations.LongDivision},
];

export const SingleDigitNumbers = {
Range: [0,1,2,3,4,5,6,7,8,9],
LowRange: [0,1,2,3,4],
HighRange: [5,6,7,8,9]
};
export const DoubleDigitNumbers = {
Range: [10,20,30,40,50,60,70,80,90],
LowRange: [10,20,30,40],
HighRange: [50,60,70,80,90]
}

export const TripleDigitNumbers = {
Range: [100,200,300,400,500,600,700,800,900],
LowRange: [100,200,300,400],
HighRange: [500,600,700,800,900]
}

export enum RegroupingOptions {
  RegroupingOnly = 'Regrouping Only',
  NoRegrouping = 'No Regrouping',
  Both = 'Both'
}