import { View, Text } from "@react-pdf/renderer"
import React from "react"
import { PdfSharedStyles as styles } from "./Stylesheet.jsx"

export function BlankHeader() {
  return (
    <View style={styles.headerContainer}>
    </View>
  );
}

  export function DocumentFooter() {
    return (
    <View style={styles.footerContainer}>
      <Text style={styles.footer}>Math-Pages.com</Text>
    </View>
  );
}

export function DocumentHeader() {
  return (
    <View style={styles.headerContainer}>
      <Text style={styles.header}>Name:</Text>
      <Text style={{...styles.header, textDecoration:'underline'}}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Text>
      <Text style={styles.header}>Date:</Text>
      <Text style={{...styles.header, textDecoration:'underline'}}>        
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Text>
    </View>
  )   
}