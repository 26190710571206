import React, { useContext } from "react";
import { ValueRange } from "./ValueRange.tsx";
import { PageSettings } from "./PageSettings.tsx";
import { LongDivisionSettings } from "./LongDivisionSettings.tsx";
import { SettingsContext } from "./SettingsContext.tsx";
import { Operations } from "../Constants.ts";
import { AdditionSubtractionSettings } from "./AdditionSubtractionSettings.tsx";

export function SettingsContainer() {   
    const {settings} = useContext(SettingsContext);

    return(
        <div className='settingsContainer'>
            {settings.Operators.findIndex(x => x.value === Operations.LongDivision)  >= 0 &&
             <LongDivisionSettings/>}
             {
                settings.Operators.findIndex(x => 
                    x.value === Operations.Addition ||
                    x.value === Operations.Subtraction
                )  >= 0 &&
                <AdditionSubtractionSettings/>
             }
            <div className='valueRangeContainer'>
                <ValueRange RangeSettings={0}/>
                <ValueRange RangeSettings={1}/>
            </div>
            <PageSettings/>          
        </div>
    );
}