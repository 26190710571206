import { StyleSheet } from '@react-pdf/renderer';

export const PdfSharedStyles = StyleSheet.create({
  headerContainer: {
    textAlign:  'justify',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    margin: '25px 30px 0px 50px', 
  },
  footerContainer: {
    height: '15px',
    position: 'absolute',
    bottom: '15',
    right: '15',
  },
  footer: {
    textAlign: 'right',
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
  header: {
    fontSize: 24,
    fontFamily: 'Helvetica',
    margin: '0px 5px 0px 5px',
  },
  viewer: {
    width: window.innerWidth * .5, //the pdf viewer will take up all of the width and height
    height: '950px',
  },   
});

  