import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { PdfGeneratorStyles as styles } from '../PdfGenerator/Stylesheet.jsx';
import { ProblemData } from '../../MathFactsGenerator/Constants.ts';

export function GenerateVerticalProblem(problemData: ProblemData, problemCount: number,
   halfPage = false, answerPage = false, longDivision = false, remainders = false) {
  let fontSize = problemCount > 30 ? styles.font15 : styles.font26;
  let containerHeight = problemCount > 30 ? styles.height72 : styles.height120;
  if (halfPage) {
    switch(problemCount){
      case 15: {
        containerHeight = styles.height100;
        break;
      }
      case 21: {
        fontSize = styles.font26;
        containerHeight = styles.height100;
        break;
      }
      case 25: {
        fontSize = styles.font20;
        containerHeight = styles.height72;
        break;
      }
      case 30: {
        fontSize = styles.font20;
        containerHeight = styles.height72;
        break;       
      }
    }
  }
  if (longDivision) {
    fontSize = styles.font20;
    containerHeight = styles.height100;
    if (halfPage) {
      if (problemCount === 10) {
        containerHeight = styles.height120;
      }
      else {
        containerHeight = styles.height100;      
      }
    } else {
      if (problemCount === 30) {
        containerHeight = styles.height120;
      } else {
        containerHeight = styles.height150;
      }
    }
    // full page 30 heigh125
    // half page 15,21 height100  10 height120

    const answer = remainders && problemData.numbers[3] > 0 ?
     problemData.numbers[2] + 'r' + problemData.numbers[3] : 
     ' ' + problemData.numbers[2];

    return(
      <View key={problemData.key} style={{...containerHeight}}>
        <Text style={{...styles.digit, ...fontSize}}>{answerPage === true ? answer : ""}</Text>
        <View style={{...styles.longDivisionProblemContainer}}>          
          <Text style={{...styles.digit, ...fontSize, ...styles.longDivisionFirstNumber}}>{problemData.numbers[1]}</Text>
          <Text style={{...styles.digit, ...styles.longDivisionSecondNumber, ...fontSize}}>{problemData.numbers[0]}</Text>        
        </View>
      </View>
    );
  }
  return(
    <View key={problemData.key} style={{...styles.problemContainer, ...containerHeight}}>
      <Text style={{...styles.digit, ...fontSize}}>{problemData.numbers[0]}</Text>
      <Text style={{...styles.digit, ...styles.bottomDigit, ...fontSize}}>{problemData.operator} {problemData.numbers[1]}</Text>
      <Text style={{...styles.digit, ...fontSize}}>{answerPage === true ? problemData.numbers[2] : ""}</Text>
    </View>
  );
}