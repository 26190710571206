import React, { useContext } from "react";
import { NumberOfProblems } from "./NumberOfProblems.tsx";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { ToggleButton } from "primereact/togglebutton";
import useScreenOrientation from "../../Shared/DeviceOrientation.tsx";
import { isMobile } from "react-device-detect";
import { generateUrl } from "../UrlProcessor/GenerateUrl.tsx";
import { SettingsContext } from "./SettingsContext.tsx";

export function PageSettings() {
    const {settings, setSettings} = useContext(SettingsContext);
    function handlePagesChange(e) {
        let value = parseInt(e.value);
        if (value > 50) {
            value = 50;
        }
        
        let updatedSettings = {...settings};
        updatedSettings.NumberOfPages = isNaN(value) ? 1 : value;
        setSettings(updatedSettings);
    } 
    
    function handleAnswerPagesToggle(value) {
        let updatedSettings = {...settings};
        updatedSettings.AnswerPages = value;
        setSettings(updatedSettings);
    } 

    function handleHalfPageToggle(value) {
        let updatedSettings = {...settings};
        updatedSettings.HalfPage = value;
        updatedSettings.NumberOfProblemsPerPage = 21;
        setSettings(updatedSettings);
    } 
    
    function handleGenerateURLClick() {
        let clipboardMessage = document.getElementById('clipboardMessage');
        clipboardMessage?.classList.remove('hidden');
        
        let url = generateUrl(settings);
        navigator.clipboard.writeText(url);
    
        //http://localhost:3000?Addition=1&Subtraction=0&Multiplication=0&Division=0&v1d=1,v1r='5'&v2d=1&v2r='0,1,2,3,4,5,6,7,8,9'&ppp=30&np=1&ap=0
    }

    return (
        <div className='pageSettings'>
            <div>
                <div className='pageSettingsCol1'>
                    <div className='answerPagesContainer'>
                        <label>Half Page: </label>
                        <ToggleButton className="answerPagesButton" checked={settings.HalfPage} onChange={(e) => handleHalfPageToggle(e.value)}  />
                    </div>
                    <label>Number of Problems Per Page:</label>
                    <NumberOfProblems/>                    
                </div>                                     
            </div>
            <div>
                <div className='pageSettingsCol2'>
                    <label>Number of Pages: </label>
                    <InputNumber className='pagesInput' value={settings.NumberOfPages} onChange={handlePagesChange} showButtons/>
                    <div className='answerPagesContainer'>
                        <label>Include Answer Pages: </label>
                        <ToggleButton className="answerPagesButton" checked={settings.AnswerPages} onChange={(e) => handleAnswerPagesToggle(e.value)}  />
                    </div>  
                    <Button  className='generateUrl' name='Generate URL' onClick={handleGenerateURLClick}>Generate URL</Button >
                    {(useScreenOrientation().toLowerCase().indexOf("portrait") >= 0) &&  isMobile && 
                        <Button className='openPdf' name='OpenPdf' onClick={openPdf}>Generate PDF</Button>
                    }    
                    <br/>
                    <span id='clipboardMessage' className='hidden clipboardMessage'>Copied URL to clipboard</span>                      
                </div>                        
            </div>                    
        </div>
    );
}

function openPdf(e) {        
    let pdfViewer = document.getElementById('pdfViewer');
    let iframe = pdfViewer?.getElementsByTagName('iframe')[0];
    let url = iframe?.getAttribute('src');
    if (url) {
        window.location.href = url;     
    }
}
