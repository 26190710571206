import React from 'react'
import { Adsense } from '@ctrl/react-adsense';

function AdsenseComponent() {
        //console.log("Ads Shown")
        return (
            <Adsense
                className='ExampleAdSlot'
                client="ca-pub-4707237658182375"
                slot="3053473161"
                adTest='on' //Dev Only
            />
        )

    }

export default AdsenseComponent