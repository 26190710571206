import { SettingsProps } from "../Models";

export function generateUrl(settings: SettingsProps) {
    let url = window.location.origin + '?';
    let operators = settings.Operators.map((operator) => operator.value);
    operators.forEach((operator) => {
        url += operator + '=1&';
    });
    url += 'v1d=' + settings.NumberRanges[0].NumberOfDigits + '&';
    url += 'v1r=' + settings.NumberRanges[0].NumberRangeSelection.join(',') + '&';
    url += 'v2d=' + settings.NumberRanges[1].NumberOfDigits + '&';
    url += 'v2r=' + settings.NumberRanges[1].NumberRangeSelection.join(',') + '&';
    url += 'ppp=' + settings.NumberOfProblemsPerPage + '&';
    url += 'np=' + settings.NumberOfPages + '&';
    url += 'ap=' + (settings.AnswerPages ? '1' : '0') + '&';
    url += 'hp=' + (settings.HalfPage ? '1' : '0') + '&';
    url += 'r=' + (settings.Remainders ? '1' : '0') + '&';
    url += 'rg=' + settings.Regrouping;

    return url;
}