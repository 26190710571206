import React from "react";
import { Settings } from "./Settings.tsx";
import { isMobile } from 'react-device-detect';
import useScreenOrientation from '../Shared/DeviceOrientation.tsx';
import { DefaultSettings } from "./Models.ts";
import { SettingsContext } from "./SettingsContext.tsx";
import { PDFViewer, Document } from "@react-pdf/renderer";
import { Pages } from "./PdfGenerator/Pages.tsx";
import { PdfSharedStyles as styles } from "../Shared/Pdf/Stylesheet.jsx";

export function Charts() {
    const [settings, setSettings] = React.useState(DefaultSettings);

    let numbers: number[] = [];
    for (let i = 1; i <= 100; i++) {
        numbers.push(i);
    }

    const hidePdfPreview = 
        !(useScreenOrientation().toLowerCase().indexOf("portrait") >= 0) &&
        !isMobile ? '' : 'hidden';  

    return (
        <SettingsContext.Provider value={{settings, setSettings}}>
            <div>                
                <h1>Hundreds Chart</h1>          
                <Settings/>
                <div id='pdfViewer' className={hidePdfPreview}> 
                    <PDFViewer style={styles.viewer}>
                        <Document title={'Hundreds Chart'}>
                            <Pages settings= {settings}/>
                        </Document>
                    </PDFViewer>
                </div>                       
            </div>
        </SettingsContext.Provider>
    );

}