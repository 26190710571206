import { StyleSheet } from '@react-pdf/renderer';

export const PdfGeneratorStyles = StyleSheet.create({
    table: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
      padding: 0,
    },
    cell: {
      fontSize: 30,
      width: '55px',
      height: '65px',
      border: '1px solid black',
      textAlign: 'center',
      margin: 0,
      padding: 0,
      paddingTop: '15px',
    },
  });