import { OperationOptions, SelectOption , Operations, SingleDigitNumbers, RegroupingOptions} from "./Constants.ts";

export interface NumberRange{
    Id: number;
    NumberOfDigits: number;
    NumberRange: number[];
    NumberRangeSelection: number[];
}

export interface SettingsProps {
    Operators: SelectOption[];
    NumberRanges: NumberRange[];
    HalfPage: boolean;
    NumberOfProblemsPerPage: number;
    NumberOfPages: number;    
    AnswerPages: boolean;
    Remainders: boolean;
    Regrouping: RegroupingOptions;
}

export const DefaultSettings: SettingsProps = {
    Operators: [OperationOptions.filter(op => op.value === Operations.Addition)[0]],
    HalfPage: false,
    NumberOfProblemsPerPage: 30,
    NumberOfPages: 1,
    NumberRanges: [
        {
            Id: 1,
            NumberOfDigits: 1, 
            NumberRange: SingleDigitNumbers.Range,
            NumberRangeSelection: [5]
        },
        {
            Id: 2,
            NumberOfDigits: 1, 
            NumberRange: SingleDigitNumbers.Range,
            NumberRangeSelection: SingleDigitNumbers.Range,
        },
    ],
    AnswerPages: false,
    Remainders: false,
    Regrouping: RegroupingOptions.Both, 
}

export const HalfPageNumberOfProblemsOptions = [10, 15, 21, 30, 40];
export const HalfPageLongDivisionNumberOfProblemsOptions = [10, 15, 21];
export const FullPageNumberOfProblemsOptions = [20, 25, 30, 60, 100];
export const FullPageLongDivisionNumberOfProblemsOptions = [20, 25, 30];
//export const RegroupingOptions = ['Regrouping Only', 'No Regrouping', 'Both'];