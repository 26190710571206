import React from 'react';
import { Page } from '@react-pdf/renderer';
import { GeneratePage } from './Page.tsx';
import { DocumentFooter, DocumentHeader } from '../../Shared/Pdf/PdfComponents.tsx';

export function Pages({ settings }) {

  let pages: JSX.Element[] = [];
  for (let i = 0; i < settings.NumberOfPages; i++) {
    const page: JSX.Element = (
      <Page key={i}>
        <DocumentHeader />
          <GeneratePage settings={settings}/> 
        <DocumentFooter />
      </Page>  
    );
    pages.push(page);
  }
   
  return pages;
}


