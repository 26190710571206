import { InputNumber } from "primereact/inputnumber";
import React, { useContext } from "react";
import { SettingsContext } from "./SettingsContext.tsx";
import { RadioButton } from "primereact/radiobutton";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import { MissingNumberOptions } from "./Models.ts";

export function Settings() {
    const {settings, setSettings} = useContext(SettingsContext);

    function handleNumberOfMissingSelect(e) {
        let updatedSettings = {...settings};
        updatedSettings.NumberOfMissingNumbers = Number(e.target.value);
        setSettings(updatedSettings);
    } 
    
    function handlePagesChange(e) {
        let value = parseInt(e.value);
        if (value > 50) {
            value = 50;
        }
        
        let updatedSettings = {...settings};
        updatedSettings.NumberOfPages = isNaN(value) ? 1 : value;
        setSettings(updatedSettings);
    } 
    let radioButtonList: React.JSX.Element[] = [];
    let i = 1;
    MissingNumberOptions.forEach(option => {
        radioButtonList.push(
            <div key={i}>
                <RadioButton  value={option} checked={settings.NumberOfMissingNumbers === option} onChange={handleNumberOfMissingSelect}/>
                <span>{option}</span>
            </div>
        )
        i++;
    });
    
    const radioButtons = (
        <div className='radioButtons'>
            {radioButtonList}
        </div>
    );

    return (
        <div>
            <label>Number of Missing Numbers: </label>
            {radioButtons}
            <br/>
            <label>Number of Pages: </label>
            <InputNumber  className='pagesInput' value={settings.NumberOfPages} onChange={handlePagesChange} showButtons/>
        </div>
    )
}