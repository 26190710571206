import { SelectButton } from "primereact/selectbutton";
import React, { useContext } from "react";
import { RangeButtons } from "./RangeButtons.tsx";
import { DoubleDigitNumbers, Operations, SingleDigitNumbers, TripleDigitNumbers } from "../Constants.ts";
import { DefaultSettings } from "../Models.ts";
import { CheckBoxes } from "./Checkboxes.tsx";
import { SettingsContext } from "./SettingsContext.tsx";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';

export function ValueRange({RangeSettings}) {
    const {settings, setSettings} = useContext(SettingsContext);
    const range = settings.NumberRanges[RangeSettings];
    function handleDigitSelection(value, rangeGroup) {
        let updatedSettings = JSON.parse(JSON.stringify(settings)); 
        let range = updatedSettings.NumberRanges.filter(x => x.Id === rangeGroup)[0];
        range.NumberOfDigits = value;
        const defaultSettings = JSON.parse(JSON.stringify(DefaultSettings));
        const defaultRange = defaultSettings.NumberRanges.filter(x => x.Id === rangeGroup)[0].NumberRangeSelection;        
        switch(value) {
            case 1: {
                range.NumberRange = SingleDigitNumbers.Range;
                range.NumberRangeSelection = defaultRange;
                // if (rangeGroup === 1 && isLongDivision) {
                //     const longDivisionRange = SingleDigitNumbers.Range.map(x => x);
                //     longDivisionRange.shift();
                //     range.NumberRange = longDivisionRange;
                // }
                break;
            }
            case 2: {
                range.NumberRange = DoubleDigitNumbers.Range;
                if (rangeGroup === 1) {
                    range.NumberRangeSelection =  [DoubleDigitNumbers.Range[4]]
                 } else {
                    range.NumberRangeSelection =  DoubleDigitNumbers.Range
                 }   
                break;
            }
            case 3: {
                range.NumberRange = TripleDigitNumbers.Range;
                if (rangeGroup === 1) {
                    range.NumberRangeSelection =  [TripleDigitNumbers.Range[4]]
                 } else {
                    range.NumberRangeSelection =  TripleDigitNumbers.Range
                 }   
                break;         
            }
        }
        setSettings(updatedSettings);
    }
    const isLongDivision = settings.Operators.findIndex(x => x.value === Operations.LongDivision) >= 0;
    let digitOptions = [1,2,3];
    if (isLongDivision) {
        digitOptions = [1,2];
    }
    return (
        <div key={range.Id} className='valueRange'>
            <div>
            Value Range ({range.Id})
            </div>
            <div className='selectContainer'>
                <label>Number of Digits: </label>
                <SelectButton 
                    value={range.NumberOfDigits} 
                    onChange={(e) => handleDigitSelection(e.value, range.Id)} 
                    options={ digitOptions }
                    className='select'                    
                />
            </div>
            <div id={'numberRangeOptions'+range.Id}>
                <RangeButtons rangeGroup={range.Id} range={range.NumberRange}/>
                <div className='checkboxContainer'>
                    <CheckBoxes 
                        numberRange={range.NumberRange} 
                        numberSelection={range.NumberRangeSelection} 
                        rangeGroup={range.Id}/>
                </div>
            </div>
        </div>
    );
}

