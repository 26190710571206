import { SelectButton } from "primereact/selectbutton";
import React, { useContext } from "react";
import { OperationOptions, Operations } from "../Constants.ts";
import { SettingsContext } from "./SettingsContext.tsx";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
//import { DefaultSettings } from "../Models.ts";

export function Operators() {
    const {settings, setSettings} = useContext(SettingsContext);

    function handleOperatorSelection(values) {
        let updatedSettings = JSON.parse(JSON.stringify(settings));  

        const longDivisionOperatorSelected = values.includes(Operations.LongDivision.valueOf());
        if (longDivisionOperatorSelected) {        
            const lastAddedOperator = values[values.length - 1];
            //const defaultRange = DefaultSettings.NumberRanges[0].NumberRange.map((number) => number);
            if (lastAddedOperator === Operations.LongDivision.valueOf()) {
                values = [lastAddedOperator];
                // if (updatedSettings.NumberRanges[0].NumberOfDigits === 1) {
                //     defaultRange.shift();
                //     updatedSettings.NumberRanges[0].NumberRange = defaultRange;
                //     const numberSelection = updatedSettings.NumberRanges[0].NumberRangeSelection;
                //     const zeroIndex = numberSelection.findIndex(x => x === 0);
                //     if (zeroIndex >= 0) {
                //         numberSelection.splice(zeroIndex, 1);
                //         updatedSettings.NumberRanges[0].NumberRangeSelection = numberSelection;
                //     }
                // }
            } else {
                values = values.filter((operator) => operator !== Operations.LongDivision.valueOf());

                // if (updatedSettings.NumberRanges[0].NumberOfDigits === 1) {
                    
                //     updatedSettings.NumberRanges[0].NumberRange = defaultRange;
                // }
            }            
        }

        updatedSettings.Operators = OperationOptions.filter((operator) => values.includes(operator.value));
        setSettings(updatedSettings);
    }

    return (
        <div className='operatorsContainer'>
            <SelectButton 
                value={settings.Operators.map((operator) => operator.value)} 
                onChange={(e) => handleOperatorSelection(e.value)}
                optionLabel="value"
                options={OperationOptions}
                multiple
                className='select operators'
            />
        </div>
    );
}