import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { PdfGeneratorStyles as styles } from './Stylesheet.jsx';

export function GeneratePage({ settings }) {

  let missingNumbers: number[] = [];

  for (let i = 0; i < settings.NumberOfMissingNumbers; i++) {
    missingNumbers.push(Math.floor(Math.random() * 99));
  }

  Math.floor(Math.random() * 100);
  let cells: JSX.Element[] = [];
    for (let i = 1; i <= 100; i++) {
      const isBlank = !(missingNumbers.indexOf(i) >= 0);    
      cells.push(
        (
          <View key={i} style={{...styles.table}}>
            <Text style={{...styles.cell}}>{isBlank && i}</Text>
          </View>
        )
      );
    }    
  return (  
    <View style={{...styles.table}}>
        {cells}
    </View>    
  )
}

