import { Checkbox } from "primereact/checkbox";
import React, { useContext } from "react";
import { SettingsContext } from "./SettingsContext.tsx";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';

export function CheckBoxes(props) {
    const {settings, setSettings} = useContext(SettingsContext);
    let checkboxes: JSX.Element[] = [];
    for (let i = 0; i < props.numberRange.length; i++) {
        const checked = props.numberSelection.includes(props.numberRange[i]) ? true : false;
        checkboxes.push(
            <div key={i} className='checkbox'>
                <label>
                {
                    props.numberRange[i] > 9 ? 
                    props.numberRange[i]+'s': 
                    props.numberRange[i]}
                </label>
                <Checkbox                         
                    name={props.numberRange[i]} 
                    checked={checked}
                    onChange={(e) => handleNumberRangeCheckboxSelect(e, props.rangeGroup, settings, setSettings)
                }/>
            </div>
        );        
    }
    return checkboxes;
}

function handleNumberRangeCheckboxSelect(e, rangeGroup, settings, setSettings) {
    let updatedSettings = {...settings};
    let range = updatedSettings.NumberRanges.filter(x => x.Id === rangeGroup)[0];
    range.NumberRangeSelection = 
        e.target.checked ? 
        [...range.NumberRangeSelection, parseInt(e.target.name)] : 
        range.NumberRangeSelection.filter((number: number) => number !== parseInt(e.target.name));
    setSettings(updatedSettings);
} 