import { ToggleButton } from "primereact/togglebutton";
import React, { useContext } from "react";
import { SettingsContext } from "./SettingsContext.tsx";


export function LongDivisionSettings() {
    const {settings, setSettings} = useContext(SettingsContext);

    function handleRemainderToggle(value) {
        let updatedSettings = {...settings}; 
        updatedSettings.Remainders = value;
        setSettings(updatedSettings);
    }
    return(
        <div className='longDivisionSettingsContainer'>
            <div className='remainderPagesContainer'>
                <label>Remainders: </label>
                <ToggleButton className="remainderToggle" checked={settings.Remainders} onChange={(e) => handleRemainderToggle(e.value)}  />
            </div>          
        </div>
    );
}